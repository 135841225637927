import React from 'react';

function Divider(props) {

  return (
    <div>
      <hr style={{margin: "35px 0 35px 0"}}/>
    </div>
  );
} 

export default Divider;